import { setCartItems } from '@/actions/cart.actions';
import { useAppDispatch } from '@/store';
import axios from 'axios';
import { useEffect } from 'react';
import { SITE_SET } from '@/constants/site.constants';
import { useQuery } from 'react-query';

export const useCartItems = (show: boolean) => {
  const dispatch = useAppDispatch();

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ['cartItems'],
    queryFn: () => axios.get(jsRoutes.controllers.Cart.view().url),
    enabled: Boolean(show),
  });

  useEffect(() => {
    if (data) {
      const {
        items,
        summary,
        relatedItems,
        removedOutOfStockItems,
        potentialPromoCode,
        potentialFreeGift,
        site,
      } = data.data;

      dispatch(
        setCartItems({
          items,
          summary,
          relatedItems,
          removedOutOfStockItems,
          potentialPromoCode,
          potentialFreeGift,
        })
      );

      site &&
        dispatch({
          type: SITE_SET,
          payload: site,
        });
    }
  }, [data]);

  return { isLoading: isFetching || isLoading };
};
