import { MobileNavLinks } from './features/mobile-nav/components/MobileNavLinks';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { createRoot } from 'react-dom/client';

const rootEl = document.getElementById('mobileNavMenuPortal');

if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary silent>
      <MobileNavLinks {...rootEl.dataset} />
    </ErrorBoundary>
  );
}
