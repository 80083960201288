import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import NavSearch from '@features/nav-search';
import { createRoot } from 'react-dom/client';
import { QueryClient } from 'react-query';
import { QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const rootEl = document.getElementById('navSearchPortal');

if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary silent>
      <QueryClientProvider client={queryClient}>
        <NavSearch />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
