import './globalInit.js';

import './main.js';

import './react-components/src/MobileNavLinksRoot';
import './react-components/src/HeaderDropdownsRoot';
import './react-components/src/VersionWatermarkRoot';
import './react-components/src/GridElementsRoot';
import './react-components/src/MiniCartRoot';
import './react-components/src/DebugRoot';
import './react-components/src/NavSearchRoot';
window.siteId = 2;
