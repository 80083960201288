import { useMenuHeight } from '@hooks';
import cx from '@utils/cx.util';
import React from 'react';
import { useCartItems } from '../hooks/useCartItems';
import LoadingSpinner from '@components/tailwind/LoadingSpinner';
import { AppState } from '@/store';
import { useSelector } from 'react-redux';
import CartHeader from '../CartHeader';
import FreeShippingMeter from '@components/tailwind/FreeShippingMeter';
import { useCartSummary } from '../hooks';
import CheckoutButtonWidget from '../MobileWidgets/CheckoutButtonWidget';
import FreeGiftMeter from '@components/tailwind/FreeGiftMeter';
import DropdownCartItem from './DropdownCartItem';

type DropdownCartProps = {
  show: boolean;
};

const DropdownCart = ({ show }: DropdownCartProps) => {
  const { navHeight, isSticked } = useMenuHeight();
  const { isLoading } = useCartItems(show);
  const { items, summary, relatedItems, potentialPromoCode, potentialFreeGift } = useSelector(
    (state: AppState) => state.cartItemList
  );
  const displayCartSummary = useCartSummary();

  console.log({ items, summary, relatedItems, potentialPromoCode, potentialFreeGift });

  const allItems = (items || []).reduce((acc, item) => {
    return [...acc, item, ...(item.upsellItems || [])];
  }, []);

  return (
    <div
      className={cx(
        'after:tw-content-[" "] tw-right-1 tw-z-[9999999] tw-w-full tw-max-w-[400px] tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-[#00000020_0_6px_15px] tw-transition-all tw-duration-300 after:tw-absolute after:tw-left-[350px] after:tw-right-0 after:tw-top-[-9px] after:tw-m-auto after:tw-h-0 after:tw-w-0 after:tw-border-b-[10px] after:tw-border-l-[10px] after:tw-border-r-[10px] after:tw-border-b-white after:tw-border-l-transparent after:tw-border-r-transparent [&_ul]:tw-mb-0 [&_ul]:tw-list-none [&_ul]:tw-pl-0',
        {
          'tw-pointer-events-none tw-translate-y-2 tw-opacity-0': !show,
          'tw-pointer-events-auto tw-opacity-100': show,
        },
        {
          'menuFadeInDown tw-fixed': isSticked,
          'tw-absolute': !isSticked,
        }
      )}
      style={{ top: navHeight + 5 }}>
      {isLoading && (
        <div className='tw-z-[2] tw-flex tw-items-center tw-justify-center tw-bg-white/75 tw-py-5'>
          <LoadingSpinner inline delay={0} />
        </div>
      )}

      {allItems.length === 0 && !isLoading && (
        <div className='tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-py-4'>
          <p className='tw-mb-0 tw-text-center tw-text-xl tw-font-bold tw-text-muted/80'>
            Your cart is empty
          </p>
        </div>
      )}

      {!isLoading && allItems.length > 0 && (
        <>
          <div>
            <CartHeader />
          </div>

          <div className='tw-flex tw-max-h-[50vh] tw-flex-col tw-gap-3 tw-overflow-y-auto'>
            {allItems.map((item, index) => (
              <DropdownCartItem key={index} item={item} />
            ))}
          </div>

          <div className='tw-mt-3 tw-border-t tw-border-neutrals-light-grey'>
            {potentialFreeGift &&
              potentialFreeGift
                .filter((g) => g.meterProgress >= 0.5)
                .map((gift, i) => <FreeGiftMeter key={i} {...gift} />)}
            <CheckoutButtonWidget />
          </div>

          <div>
            <FreeShippingMeter
              className='tw-rounded-button tw-border-y-0'
              eligibleForFreeShipping={displayCartSummary.eligibleForFreeShipping}
              amountRemainingForFreeShippingFormatted={
                displayCartSummary.amountRemainingForFreeShippingFormatted
              }
              meterProgressFormatted={displayCartSummary.meterProgressFormatted}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownCart;
