import React from 'react';
import MiniCart from './features/cart/MiniCart';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const rootEl = document.getElementById('module-mini-cart');
if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary silent>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {React.createElement(MiniCart, rootEl.dataset)}
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
}
