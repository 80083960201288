import WebImage from '@components/tailwind/WebImage';
import cx from '@utils/cx.util';
import { formatCurrency } from '@utils/formatters.util';

const DropdownCartItem = ({ item }: { item: any }) => {
  console.log({ item });

  const isFreeGift = item.isFreeGift;
  const isDiscounted = item.isDiscounted && item.total !== item.undiscountedTotal;
  const isBundle = item.isBundle;

  return (
    <a href={item.url} className='tw-block !tw-no-underline hover:!tw-no-underline'>
      {isFreeGift && <div className='tw-mt-2 tw-bg-[#DFEAF5] tw-py-2 tw-text-center'>FREE GIFT</div>}
      <div className='tw-flex tw-items-center tw-gap-3'>
        <div className='tw-w-[120px] tw-shrink-0 tw-grow-0 md:tw-w-[100px]'>
          {isBundle ? (
            <div className='tw-flex tw-flex-col tw-gap-2'>
              <div>
                <WebImage
                  webImage={item.webImages.default.thumb}
                  alt={item.displayName}
                  className='tw-max-h-[80px]'
                />
              </div>
              <div
                className={cx('tw-flex tw-justify-center tw-gap-1', {
                  'tw-flex-wrap': item.memberItems.length >= 4,
                })}>
                {item.memberItems.slice(1).map((bundleItem: any) => (
                  <WebImage
                    className='tw-max-h-[50px]'
                    key={bundleItem.id}
                    webImage={bundleItem.webImages.default.thumb}
                    alt={bundleItem.displayName}
                  />
                ))}
              </div>
            </div>
          ) : (
            <WebImage webImage={item.webImages.default.thumb} alt={item.displayName} />
          )}
        </div>
        <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-1'>
          <p className='tw-mb-0 tw-text-sm tw-font-bold'>{item.displayName}</p>
          {Boolean(item.customInfo) && (
            <div>
              <img
                src='/assets/images/rtic/myRTIC-Logo.png'
                alt='MyRTIC'
                className='tw-inline-block tw-h-[16px]'
              />
            </div>
          )}

          {item.isBundle && <BundleContents items={item.memberItems} />}

          {isFreeGift ? (
            <p className='tw-mb-0 tw-text-xs tw-font-normal'>
              Price: FREE <del>{formatCurrency(item.undiscountedTotal)}</del>
            </p>
          ) : (
            <>
              <p className='tw-mb-0 tw-text-xs tw-font-normal'>Quantity: {item.quantity}</p>
              <p className='tw-mb-0 tw-text-xs tw-font-normal'>
                Price:{' '}
                {isDiscounted ? (
                  <>
                    {formatCurrency(item.total)} <del>{formatCurrency(item.undiscountedTotal)}</del>
                  </>
                ) : (
                  formatCurrency(item.total)
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </a>
  );
};

export default DropdownCartItem;

const BundleContents = ({ items }: { items: any[] }) => {
  return (
    <div className='tw-rounded-button tw-border tw-border-neutrals-light-grey tw-bg-gray-50 tw-p-2'>
      <p className='tw-mb-1 tw-text-sm tw-font-bold'>Bundle Contents:</p>
      <ul className='tw-mb-0 !tw-list-disc !tw-pl-4'>
        {items.map((item) => (
          <li key={item.id} className='tw-mb-[2px] tw-p-0 tw-text-xs tw-font-normal last:tw-mb-0'>
            {item.displayName}
          </li>
        ))}
      </ul>
    </div>
  );
};
