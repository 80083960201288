import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import { lazyRetry } from '@utils/lazyRetry';
import useVariation from '@hooks/useVariation';
import useABTests from '@hooks/useABTests';
import services from '@/services';
import DropdownCart from './DropdownCart';

const LazyCart = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "lazyCart" */ './LazyCart'), 'lazyCart')
);

const MiniCart = () => {
  useABTests();

  const [showCart, setShowCart] = useState(null);

  const wrapperRef = useRef<HTMLDivElement>();

  const isVariant = useVariation('Mini Cart');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!!wrapperRef.current && !wrapperRef?.current?.contains(event.target)) {
        setShowCart(false);
      }
      if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'show');
      }
    };
    const handleClickInside = (event) => {
      event.stopPropagation();
      setShowCart(true);
      services.abtasty.trackAction('Click Mini Cart', 'Mini Cart');
      if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'hide');
      }
      return false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.getElementById('module-mini-cart-toggle').addEventListener('click', handleClickInside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.getElementById('module-mini-cart-toggle').removeEventListener('click', handleClickInside);
    };
  }, []);

  if (!isVariant)
    return (
      <div ref={wrapperRef}>
        <DropdownCart show={showCart} />
      </div>
    );

  return (
    <>
      <div
        ref={wrapperRef}
        className={classNames(
          'tw-transition-right tw-fixed tw-bottom-0 tw-top-0 tw-z-[9999999] tw-w-[calc(100%-2rem)] tw-max-w-[30rem] tw-overflow-hidden tw-shadow-[#00000020_0_6px_15px] tw-duration-300 lg:tw-w-[30rem] lg:tw-duration-500',
          {
            'tw-right-0': showCart,
            '-tw-right-full': !showCart,
          }
        )}>
        <div className='tw-flex tw-h-full tw-w-full'>
          <div className='tw-flex tw-h-full tw-w-full tw-grow tw-whitespace-nowrap tw-bg-white'>
            {showCart !== null && (
              <React.Suspense>
                <LazyCart closeCart={() => setShowCart(false)} show={showCart} />
              </React.Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniCart;
